import './Filter.css';
import { t } from '../T';
import Radio from '../Radio/Radio';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import Textfield from '../Textfield/Textfield';
import useDebounce from '../../helpers/hooks/useDebounce';
import FilterTextField from './components/FilterTextField';
import { handleTextIndicator } from '../../helpers/strings';
import { useEffect, useMemo, useRef, useState } from 'react';
import CheckboxNew from '../../components/CheckboxNew/CheckboxNew';
import { useClickOutside } from '../../helpers/hooks/useClickOutside';
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';

const filterIndicatorWidth = 30;

const Filter = ({
  dataKey,
  onChange,
  disabled,
  fullWidth,
  items = [],
  placeholder,
  displayIcon,
  dataNameKey,
  roundedIcon,
  multiSelect,
  noOptionText,
  selectedItems,
  customIconKey,
  selectAllField,
  hasSearchOption,
  customLabelTemplate,
  variant = 'checkbox', // "radio" || "checkbox",
  customTextfieldLabel,
  searchFieldPlaceholder,
  searchOptionApiCallback,
  disableFocusShadowEffect,
}) => {
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const wrapperRef = useRef('menu');
  const selectedFieldsSpanRef = useRef(null);
  const [lastEvent, setLastEvent] = useState(); // "ADD" or "REMOVE" element
  const [searchText, setSearchText] = useState('');
  const [indicatorData, setIndicatorData] = useState({
    show: false,
    visibleSelectedItems: [],
  });
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
  });

  const selectedItemsFiltered = useMemo(() => {
    return selectedItems?.length
      ? items?.filter((item) =>
          selectedItems?.some((selItem) => item?.[dataKey || 'id'] === selItem?.[dataKey || 'id']),
        )
      : [];
  }, [selectedItems, items]);

  const selectedItemsToShow = indicatorData?.show
    ? indicatorData?.visibleSelectedItems
    : selectedItemsFiltered;

  useEffect(() => {
    if (hasSearchOption && searchOptionApiCallback) {
      searchOptionApiCallback();
    }
  }, []);

  useDebounce(() => searchOptionApiCallback(searchText), 500, [searchText]);

  const handleOnChange = (filteredArray) => {
    if (onChange) {
      onChange(filteredArray);
    }
  };

  const generateRowLabel = (item) => {
    if (customLabelTemplate) {
      return customLabelTemplate(item);
    }
    return item?.[dataNameKey || 'name'];
  };

  const handleCheckAll = () => {
    if (selectedItemsFiltered && items && selectedItemsFiltered?.length === items?.length) {
      handleOnChange([]);
    } else {
      setLastEvent('REMOVE');
      handleOnChange(items);
    }
  };

  const handleCheckboxClick = (data) => {
    if (!multiSelect) {
      setLastEvent('ADD');
      handleOnChange([data]);
      setOpen(false);
      return;
    }
    // eslint-disable-next-line
    if (selectedItemsFiltered?.some((item) => item?.[dataKey || 'id'] == data?.[dataKey || 'id'])) {
      const newItems = selectedItemsFiltered?.filter(
        (item) => item?.[dataKey || 'id'] !== data?.[dataKey || 'id'],
      );
      setLastEvent('REMOVE');
      handleOnChange(newItems);
    } else {
      const newItems = selectedItemsFiltered?.length > 0 ? [...selectedItemsFiltered, data] : [data];
      setLastEvent('ADD');
      handleOnChange(newItems);
    }
  };

  useClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  useEffect(() => {
    if (referenceElement) {
      handleTextIndicator(
        selectedItemsFiltered,
        dataNameKey,
        referenceElement,
        indicatorData,
        setIndicatorData,
        filterIndicatorWidth,
        selectedFieldsSpanRef,
        lastEvent,
      );
    }
  }, [selectedItems, referenceElement]);

  return (
    <div ref={wrapperRef} className={`filter-main-wrapper-new ${fullWidth ? 'fullWidth' : ''}`}>
      <FilterTextField
        open={open}
        setOpen={setOpen}
        items={items}
        selectedItems={selectedItemsFiltered}
        selectedItemsToShow={selectedItemsToShow}
        selectedFieldsSpanRef={selectedFieldsSpanRef}
        textFieldRef={setReferenceElement}
        placeholder={placeholder}
        indicatorData={indicatorData}
        dataNameKey={dataNameKey}
        isMultiSelect={multiSelect}
        disabled={disabled}
        customTextfieldLabel={customTextfieldLabel}
      />
      {open && (
        <div
          className={`checkboxes-new${fullWidth ? ' fullWidth' : ''}`}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {hasSearchOption && (
            <Textfield
              type='text'
              fullWidth
              icon={<SearchIcon />}
              placeholder={searchFieldPlaceholder}
              disableFocusShadowEffect={disableFocusShadowEffect}
              size='lg'
              value={searchText}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          )}
          {selectAllField && (
            <div className='filter-checkbox-wrapper'>
              {variant === 'checkbox' ? (
                <CheckboxNew
                  id='all'
                  data={{ id: 'all', name: 'All' }}
                  label={t(lng, 'app.filters.all')}
                  onChange={handleCheckAll}
                  value={
                    selectedItemsFiltered?.length === items?.length ||
                    (selectedItemsFiltered?.length === 0 && !multiSelect)
                  }
                  multiSelect={multiSelect}
                />
              ) : (
                <Radio
                  id='all'
                  data={{ id: 'all', name: 'All' }}
                  label={t(lng, 'app.filters.all')}
                  onChange={handleCheckAll}
                  value={
                    selectedItemsFiltered?.length === items?.length ||
                    (selectedItemsFiltered?.length === 0 && !multiSelect)
                  }
                  multiSelect={multiSelect}
                />
              )}
            </div>
          )}
          {items &&
            items.length > 0 &&
            items.map((item, key) => {
              return (
                <div className='filter-checkbox-wrapper' key={key}>
                  {variant === 'checkbox' ? (
                    <CheckboxNew
                      id={item?.[dataKey || 'id']}
                      item={item}
                      color={item?.color}
                      label={generateRowLabel(item)}
                      displayIcon={displayIcon}
                      roundedIcon={roundedIcon}
                      customIcon={item?.[customIconKey]}
                      multiSelect={multiSelect}
                      onChange={() => handleCheckboxClick(item)}
                      value={
                        multiSelect
                          ? selectedItemsFiltered?.some(
                              (selectedItem) => item?.[dataKey || 'id'] === selectedItem?.[dataKey || 'id'],
                            )
                          : false
                      }
                    />
                  ) : (
                    <Radio
                      id={item?.[dataKey || 'id']}
                      color={item?.color}
                      item={item}
                      label={generateRowLabel(item)}
                      displayIcon={displayIcon}
                      roundedIcon={roundedIcon}
                      customIcon={item?.[customIconKey]}
                      onChange={() => handleCheckboxClick(item)}
                      value={selectedItemsFiltered?.some(
                        (selectedItem) => item?.[dataKey || 'id'] === selectedItem?.[dataKey || 'id'],
                      )}
                    />
                  )}
                </div>
              );
            })}
          {items?.length === 0 && noOptionText && (
            <div className='filter-checkbox-wrapper'>
              <span className='filter-no-option'>{noOptionText}</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Filter;
