import React, { useEffect, useState } from 'react';
import { T } from '../../../T';
import ReactLoading from 'react-loading';
import Accordion from '../../../Accordion/Accordion';
import './AssetsTable.css';
import AssetAccordionDetails from '../../../Pages/Assets/AssetAccordionDetails/AssetAccordionDetails';
import {
  getAddressesByAssetIdApi,
  getAddressOverviewApi,
  getAssetsByVaultApi,
} from '../../../../api/endpoints';
import AssetAccordionTitle from '../../../Pages/Assets/AssetAccordionTitle/AssetAccordionTitle';
import { useSelector } from 'react-redux';
import Skeleton from '../../../Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
import { vaultTypes } from '../../../../helpers/constants';

// Type can be "address", "vaults", "assets"
const AssetsTable = ({ type, walletId, walletData }) => {
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const typeUser = useSelector((state) => state.userReducer.typeUser);
  const [loading, setLoading] = useState(false);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState([]);
  const [assetsData, setAssetsData] = useState();
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assetAddresses, setAssetAddresses] = useState();

  const getAddressesByAssetId = (assetId, blockchain, network, symbol, accordionIndex) => {
    setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: true }));
    getAddressesByAssetIdApi(assetId, walletId, blockchain, typeUser)
      .then(async (res) => {
        // TRON STAKING
        if (
          walletData?.vaultType?.toLowerCase() === vaultTypes.GENERAL &&
          res?.data?.item?.addresses?.length > 0 &&
          blockchain?.toLowerCase() === 'tron' &&
          symbol?.toLowerCase() === 'trx'
        ) {
          const updatedAddresses = await Promise.all(
            res?.data?.item?.addresses?.map(async (address) => {
              const overview = await getAddressOverviewApi(walletId, blockchain, network, address?.address);

              return {
                ...address,
                availableAmount: overview?.data?.item?.balance?.available,
                availableFrozen: overview?.data?.item?.balance?.availableFrozen,
              };
            }),
          );
          res.data.item.addresses = updatedAddresses;
        }
        setAssetAddresses((prev) => ({
          ...prev,
          [accordionIndex]:
            res?.data?.item?.addresses?.length > 0
              ? res?.data?.item?.addresses?.map((address) => ({
                  ...address,
                  exchangeRate: res?.data?.item?.exchangeRate,
                }))
              : null,
        }));
      })
      .finally(() => setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: false })));
  };

  const getAssetsByAddress = () => {
    setLoading(true);
    getAssetsByVaultApi(walletId)
      .then((res) => {
        setAssetsData(res?.data?.items);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (walletId) {
      getAssetsByAddress();
    }
  }, [walletId]);

  return (
    <div className='accordion-table-parent'>
      <div className={`assets-table ${trialBannerVisible ? 'trial-banner-visible' : ''}`}>
        <div className='asset-table-wrapper'>
          <div className='first-column'>
            <T>app.asset</T>
          </div>
          <div className='balance'>
            <T>app.available.balance</T>
          </div>
          <div className='balance'>
            <T>app.total.balance</T>
          </div>
          <div className='balance'>
            <T>app.aml.blocked</T>
          </div>
          <div className='balance'>
            <T>app.allocated</T>
          </div>
          <div className='settings' />
        </div>
        <div className='accordions-parent'>
          {loading && (
            <div className='loading-full-height'>
              <Skeleton rowHeight={isMobile ? 404 : 151} padding={isMobile ? 20 : 50} />
            </div>
          )}
          {!loading &&
            assetsData?.map((i, index) => {
              return (
                <Accordion
                  key={index}
                  open={accordionOpenIndexes?.includes(index)}
                  onOpenCallback={(isAccordionOpened) => {
                    if (!isAccordionOpened) {
                      setAccordionOpenIndexes((prev) => [...prev, index]);
                      getAddressesByAssetId(i?.assetId, i?.blockchain, i?.network, i?.symbol, index);
                    } else {
                      setAccordionOpenIndexes((prev) =>
                        prev?.filter((accordionIndex) => accordionIndex !== index),
                      );
                    }
                  }}
                  titleComponent={
                    <AssetAccordionTitle
                      asset={{
                        ...i,
                        availableAmount: i?.assetData?.availableAmount,
                        totalAmount: i?.assetData?.amount,
                        blockedAmount: i?.assetData?.blockedBalance,
                        allocatedAmount: i?.assetData?.allocatedBalance,
                      }}
                    />
                  }
                  detailsComponent={
                    loadingAssets[index] ? (
                      <div className='team-page wallets wallets-address'>
                        <div className='loading'>
                          <ReactLoading type='spin' color='##020D1C' />
                        </div>
                      </div>
                    ) : (
                      <AssetAccordionDetails
                        asset={{
                          ...i,
                          availableAmount: i?.assetData?.availableAmount,
                          totalAmount: i?.assetData?.amount,
                          blockedAmount: i?.assetData?.blockedBalance,
                          allocatedAmount: i?.assetData?.allocatedBalance,
                        }}
                        walletData={walletData}
                        blockchain={i?.blockchain}
                        rows={
                          assetAddresses?.[index]?.map((address) => ({
                            ...address,
                            symbol: i?.symbol,
                            assetData: {
                              allocatedBalance: address?.allocatedAmount,
                              amount: address?.amount,
                              // TRON STAKING
                              frozenAvailable: address?.availableFrozen,
                              availableAmount: address?.availableAmount,
                              blockedBalance: address?.blockedAmount,
                            },
                          })) || []
                        }
                        type={type}
                      />
                    )
                  }
                />
              );
            })}
          {!loading && assetsData?.length === 0 && (
            <div className='assets-table-no-assets'>
              <div>
                <T>app.no.assets.found</T>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssetsTable;
