import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalization } from './redux/slices/localizationSlice';
import {
  LOGIN_ATTEMPT,
  STATE_DASHBOARD,
  STATE_PENDING_VERIFICATION,
  STATE_REGISTER_MOBILE,
  // STATE_REGISTER_PSP,
  STATE_VERIFY_EMAIL,
  dashboardState,
  loginUser,
} from './redux/slices/authSlice';
import { getLocalizationApi, getProfileInfoApi, getWebsocketTokenApi } from './api/endpoints';
import { isAppUnderMaintanance, whitelistedIpAddresses, BASE_URL } from './config/Common';
import Maintanance from './pages/Maintanance/Maintanance';
import { setMyProfile, setVerificationInfo } from './redux/slices/userSlice';
import useWebsocket from './helpers/hooks/useWebsocket';
import {
  ROUTE_FORGOT,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_LOGIN_ATTEMPT,
  ROUTE_REGISTER,
  ROUTE_RESET,
  ROUTE_VERIFY_EMAIL,
  ROUTE_BILLING,
  ROUTE_CONTACTS,
  ROUTE_DEVELOPERS,
  ROUTE_LOGIN_HISTORY,
  ROUTE_PROFILE,
  ROUTE_TEAM,
  ROUTE_TRX_HISTORY,
  ROUTE_TRX_POLICY,
  ROUTE_TRX_REQUESTS,
  ROUTE_WALLET,
  ROUTE_WALLETS,
  ROUTE_VERIFY_MOBILE,
  ROUTE_DOWNLOAD_APP,
  ROUTE_DASHBOARD,
  ROUTE_WEBHOOK,
  ROUTE_ERROR,
  // ROUTE_PAYMENT,
  // ROUTE_PRICING_TABLE,
} from './routes/routes';
import { SignIn, SignUp } from './pages';
import ForgotPass from './pages/ForgotPass';
import ResetPassword from './pages/ResetPassword';
import VerifyEmail from './pages/VerifyEmail';
import VerifyMobileStep1 from './pages/VerifyMobileStep1';
import Wallets from './pages/Wallets/Wallets';
import TransactionRequests from './pages/TransactionRequests/TransactionRequests';
import TransactionsHistory from './pages/TransactionsHistory/TransactionsHistory';
import GovernanceLayer from './pages/GoverananceLayer/GovernanceLayer';
import Team from './pages/Team/Team';
import Wallet from './pages/Wallet/Wallet';
import Developers from './pages/Developers/Developers';
import MyProfile from './pages/MyProfile/MyProfile';
import LoginHistory from './pages/LoginHistory/LoginHistory';
import PlanAndBilling from './pages/PlanAndBilling/PlanAndBilling';
import Contacts from './pages/Contacts/Contacts';
import Assets from './pages/Assets/Assets';
import LoginAttempt from './pages/LoginAttempt';
import AuthRoute from './routes/AuthRoute';
import { authRoutesGuardsMapping } from './helpers/constants';
import VerifyMobileStep2 from './pages/VerifyMobileStep2';
import DashboardRoute from './routes/DashboardRoute';
import WebhookHistory from './components/Tables/Developers/WebhookHistory';
import Error from './pages/Error/Error';
import LandscapeOrientationBlocker from './components/LandscapeOrientationBlocker/LandscapeOrientationBlocker';
import { useMediaQuery } from 'react-responsive';

const App = () => {
  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isMobile = useMediaQuery({
    query: '(max-width: 950px)',
  });
  const messageSocket = useSelector((state) => state.globalReducer.messageSocket);
  const [isLoading, setIsLoading] = useState(false);
  const [websocketToken, setWebsocketToken] = useState();
  const [isIpWhitelisted, setIsIpWhitelisted] = useState(false);
  const auth = useSelector((state) => state.authReducer);
  const authToken = localStorage.getItem('token');
  const dispatch = useDispatch();
  useWebsocket(
    window.location.href.includes('localhost')
      ? `${BASE_URL?.replace('https', 'wss') + '/ws'}/${websocketToken}`
      : `${`wss://${window.location.host}/ws`}/${websocketToken}`,
    websocketToken,
  );

  const getWebsocketToken = () => {
    getWebsocketTokenApi().then((res) => setWebsocketToken(res.data.item.token));
  };

  useEffect(() => {
    if (authToken && !websocketToken) {
      getWebsocketToken();
    } else {
      setWebsocketToken(auth?.token);
    }
  }, [auth?.token]);

  useEffect(() => {
    if (isAppUnderMaintanance) {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
          setIsIpWhitelisted(whitelistedIpAddresses.includes(data?.ip));
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (authToken) {
      dispatch(loginUser(authToken));
      getProfileInfoApi().then((res) => {
        const data = Object.assign(res.data.item, {
          premmisions: Object.values(res.data.item.permissions).map((i) => {
            return i.permissionName;
          }),
        });
        dispatch(setMyProfile(data));
        if (data?.state) {
          dispatch(dashboardState(data?.state));
        }
      });
    } else {
      dispatch(loginUser(false));
    }
    getLocalizationApi()
      .then((res) => dispatch(setLocalization(res.data)))
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }, []);

  // Onboarding data updated on websocket message
  useEffect(() => {
    if (typeof messageSocket === 'object') {
      if (messageSocket?.data?.event === 'client_verification_data_update') {
        console.log(messageSocket?.data);
        if (Object.keys(messageSocket?.data?.item)?.length > 0) {
          dispatch(
            setVerificationInfo({
              ...messageSocket?.data?.item,
              state: messageSocket?.data?.item?.clientStatus,
            }),
          );
        }
      }
    }
  }, [messageSocket]);

  if (isAppUnderMaintanance && !isIpWhitelisted) {
    return <Maintanance />;
  }

  return !isLoading && auth.isLogged !== undefined ? (
    <>
      {isMobile && isLandscape && <LandscapeOrientationBlocker />}
      <BrowserRouter>
        <Routes>
          {auth?.isLogged === false && (
            <>
              <Route path={ROUTE_LOGIN} element={<SignIn />} />
              <Route path={ROUTE_REGISTER} element={<SignUp />} />
              <Route path={ROUTE_FORGOT} element={<ForgotPass />} />
              <Route path={ROUTE_RESET} element={<ResetPassword />} />
              <Route path='*' element={<Navigate to={ROUTE_LOGIN} />} />
            </>
          )}
          {auth?.isLogged && auth?.state && (
            <>
              <Route
                element={
                  <AuthRoute
                    isAllowed={auth.state === LOGIN_ATTEMPT && auth?.isLogged}
                    redirectPath={authRoutesGuardsMapping[auth?.state]}
                  />
                }
              >
                <Route path={ROUTE_LOGIN_ATTEMPT} element={<LoginAttempt />} />
              </Route>
              <Route
                element={
                  <AuthRoute
                    isAllowed={auth.state === STATE_VERIFY_EMAIL && auth?.isLogged}
                    redirectPath={authRoutesGuardsMapping[auth?.state]}
                  />
                }
              >
                <Route path={ROUTE_VERIFY_EMAIL} element={<VerifyEmail />} />
              </Route>
              <Route
                element={
                  <AuthRoute
                    isAllowed={auth.state === STATE_REGISTER_MOBILE && auth?.isLogged}
                    redirectPath={authRoutesGuardsMapping[auth?.state]}
                  />
                }
              >
                <Route path={ROUTE_DOWNLOAD_APP} element={<VerifyMobileStep1 />} />
              </Route>
              <Route
                element={
                  <AuthRoute
                    isAllowed={auth.state === STATE_REGISTER_MOBILE && auth?.isLogged}
                    redirectPath={authRoutesGuardsMapping[auth?.state]}
                  />
                }
              >
                <Route path={ROUTE_VERIFY_MOBILE} element={<VerifyMobileStep2 />} />
              </Route>
              {/* <Route
                element={
                  <AuthRoute
                    isAllowed={auth.state === STATE_REGISTER_PSP && auth?.isLogged}
                    redirectPath={authRoutesGuardsMapping[auth?.state]}
                  />
                }
              >
                <Route path={ROUTE_PAYMENT} element={<PaymentPending />} />
              </Route> */}
              <Route path='*' element={<Navigate to={ROUTE_VERIFY_EMAIL} />} />
            </>
          )}
          {auth?.isLogged &&
            auth?.state &&
            (auth.state === STATE_DASHBOARD || auth.state === STATE_PENDING_VERIFICATION) && (
              <>
                <Route
                  element={
                    <DashboardRoute
                      isAllowed={auth.state === STATE_DASHBOARD || auth.state === STATE_PENDING_VERIFICATION}
                    />
                  }
                >
                  <Route path={ROUTE_HOME} element={<Assets />} />
                  <Route path={ROUTE_WALLETS} element={<Wallets />} />
                  <Route path={`${ROUTE_WALLET}/*`} element={<Wallet />} />
                  <Route path={ROUTE_TRX_REQUESTS} element={<TransactionRequests />} />
                  <Route path={ROUTE_TRX_HISTORY} element={<TransactionsHistory />} />
                  <Route path={ROUTE_CONTACTS} element={<Contacts />} />
                  <Route path={ROUTE_TRX_POLICY} element={<GovernanceLayer />} />
                  <Route path={ROUTE_TEAM} element={<Team />} />
                  <Route path={ROUTE_DEVELOPERS} element={<Developers />} />
                  <Route path={`${ROUTE_WEBHOOK}/:id`} element={<WebhookHistory />} />
                  <Route path={ROUTE_PROFILE} element={<MyProfile />} />
                  <Route path={ROUTE_LOGIN_HISTORY} element={<LoginHistory />} />
                  <Route path={ROUTE_BILLING} element={<PlanAndBilling />} />
                  {/* <Route path={ROUTE_PRICING_TABLE} element={<PricingPage />} /> */}
                  <Route path={ROUTE_ERROR} element={<Error />} />
                </Route>
                <Route path='*' element={<Navigate to={ROUTE_DASHBOARD} />} />
              </>
            )}
        </Routes>
      </BrowserRouter>
    </>
  ) : (
    <div />
  );
};

export default App;
