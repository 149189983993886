import { T } from '../T';
import BigNumber from 'bignumber.js';
import LoadMore from '../LoadMore/LoadMore';
import Skeleton from '../Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
import { Suspense, lazy, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import TransactionRequestsRow from './TransactionRequestsRow';
const ContactAddNewModal = lazy(() => import('../Pages/Contacts/ContactAddNewModal/ContactAddNewModal'));
const ContactCreateSuccessModal = lazy(
  () => import('../Pages/Contacts/ContactCreateSuccessModal/ContactCreateSuccessModal'),
);

const TransactionRequestsTable = ({
  isShelf,
  loading,
  data = [],
  loadMoreLoading,
  detailsHandler = () => {},
  transactionKey = '',
  loadMoreHandler = () => {},
  loadMore,
  isTransactionRequests,
  handleSelectTransaction,
  selectedTransactions,
  handleOpenRemoveModal,
  draftDeleteButtonVisible,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const [createdContact, setCreatedContact] = useState({ open: false });
  const [addNewContactModal, setAddNewContactModal] = useState({ open: false });

  const renderRow = ({ index, style }) => {
    const resepientsAmount = data[index]?.recipients.map((i) => {
      return BigNumber(i.amount).toNumber();
    });
    let resepientsAmountInUSD;
    if (!isShelf) {
      resepientsAmountInUSD = data[index]?.recipients.map((i) => {
        return BigNumber(i.convertedAmount).toNumber();
      });
    } else {
      resepientsAmountInUSD = data[index]?.recipients.map((i) => {
        return BigNumber(i.amount).toNumber() * data[index]?.asset?.exchangeRate;
      });
    }
    const sumreResepientsAmount = BigNumber.sum(...resepientsAmount).toFixed();
    const sumResepientsAmountInUSD = BigNumber.sum(...resepientsAmountInUSD).toFixed();
    return (
      <div style={style}>
        <TransactionRequestsRow
          key={`trx-row-${index}`}
          isDraft={isShelf}
          transaction={data[index]}
          detailsHandler={detailsHandler}
          walletName={data[index]?.wallet?.name}
          recepientAmount={sumreResepientsAmount}
          handleOpenRemoveModal={handleOpenRemoveModal}
          setAddNewContactModal={setAddNewContactModal}
          isTransactionRequests={isTransactionRequests}
          direction={data[index]?.transaction?.direction}
          recepientAmountInUSD={sumResepientsAmountInUSD}
          handleSelectTransaction={handleSelectTransaction}
          draftDeleteButtonVisible={draftDeleteButtonVisible}
          innerDirection={data[index]?.transaction?.innerDirection}
          isSelected={selectedTransactions?.some((trxId) => trxId === data[index]?.id)}
        />
        {loadMore && index === data?.length - 1 && (
          <div style={{ paddingBottom: 30 }}>
            <LoadMore isLoading={loadMoreLoading} loadMoreHandler={loadMoreHandler} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='trx-table'>
      {createdContact?.open && (
        <Suspense fallback={null}>
          <ContactCreateSuccessModal
            displayAddAnotherButton={false}
            address={createdContact?.address}
            addressName={createdContact?.name}
            blockchain={createdContact?.blockchain}
            addCallback={() => setAddNewContactModal(true)}
            closeCallback={() => {
              setCreatedContact({ open: false });
            }}
          />
        </Suspense>
      )}
      {addNewContactModal?.open && (
        <Suspense fallback={null}>
          <ContactAddNewModal
            address={addNewContactModal?.address}
            blockchain={addNewContactModal?.blockchain}
            closeCallback={() => {
              setAddNewContactModal({ open: false });
            }}
            callback={(createdContact) => {
              setAddNewContactModal({ open: false });
              setCreatedContact({ open: true, ...createdContact });
            }}
          />
        </Suspense>
      )}
      <div className='table-content'>
        <div className='table-row caption trx-table-header'>
          <div className='table-data date timestamp'>
            <T>app.date.time</T>
          </div>
          <div className='table-data wallet'>
            <T>app.from</T>
          </div>
          <div className='table-data wallet'>
            <T>app.to</T>
          </div>
          <div className='table-data level amount'>
            <T>app.amount</T>
          </div>
          <div className='table-data level'>
            <T>app.status</T>
          </div>
          <div className='table-data settings' />
        </div>
        {loading && (
          <div className='loading-full-height'>
            <Skeleton rowHeight={isMobile ? (isShelf ? 200 : 270) : 103} padding={isMobile ? 20 : 50} />
          </div>
        )}
        {!loading && data?.length > 0 && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                className='trx-window-list'
                height={height}
                itemCount={data?.length}
                itemSize={isMobile ? 317 : 103}
                width={width}
              >
                {renderRow}
              </List>
            )}
          </AutoSizer>
        )}
      </div>
      {!loading && data?.length === 0 && (
        <div className='table-row no-active'>
          <div>
            <T>{transactionKey}</T>
          </div>
        </div>
      )}
    </div>
  );
};
export default TransactionRequestsTable;
