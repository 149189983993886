import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import GuestLayout from '../components/GuestLayout';
import { ReactComponent as AtIcon } from '../assets/icons/at.svg';
import emailSentIcon from '../assets/icons/email-sent-icon.svg';
import { T, t } from '../components/T';
import ReCAPTCHA from 'react-google-recaptcha';
import { forgotPasswordApi } from '../api/endpoints';
import Textfield from '../components/Textfield/Textfield';
import Button from '../components/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import { ROUTE_HOME, ROUTE_LOGIN } from '../routes/routes';
import { useNavigate } from 'react-router-dom';

const ForgotPass = () => {
  const navigate = useNavigate();
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const [susess, setSussess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });

  const forgotPasswordHandler = async (data) => {
    setIsLoading(true);
    const token = await recaptchaRef.current.executeAsync();

    localStorage.clear();
    const payload = {
      item: {
        email: data?.email,
        reCaptchaToken: token,
      },
    };
    forgotPasswordApi(payload)
      .then(() => setSussess(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <GuestLayout>
      {!susess && (
        <>
          <form onSubmit={handleSubmit(forgotPasswordHandler)} className='guest-flow-form-wrapper'>
            <div className='guest-flow-form-main'>
              <h2 className='fw-bolder'>
                <T>app.forgot.password</T>
              </h2>
              <div className='guest-flow-subtitle'>
                <T>app.enter.link.to.reset</T>
              </div>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: t(lng, 'app.email-is-required'),
                  validate: (value) =>
                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(value) ||
                    t(lng, 'app.email-is-invalid'),
                }}
                render={({ field }) => {
                  return (
                    <>
                      <label className='guest-textfield-label'>
                        <T>app.email</T>
                      </label>
                      <Textfield
                        id='email'
                        trim
                        type='text'
                        fullWidth
                        icon={<AtIcon />}
                        size='lg'
                        autoComplete='new-password'
                        disabled={isLoading}
                        placeholder={t(lng, 'app.email-placeholder')}
                        error={errors?.email?.message}
                        {...field}
                      />
                    </>
                  );
                }}
              />
            </div>
            <div>
              {isLoading ? (
                <div className='text-center mt-5'>
                  <div className='spinner-border text-success' role='status'>
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                </div>
              ) : (
                <Button fullWidth disabled={!isValid}>
                  <T>app.send.link.to.email</T>
                </Button>
              )}
              <div className='guest-layout-footer'>
                <div className='return-container'>
                  <div className='return' onClick={() => navigate(ROUTE_LOGIN)}>
                    <i className='icon-arrow-right' />
                    <span>Back to sign in</span>
                  </div>
                </div>
                <div>
                  <T>app.issue-sign-in</T>{' '}
                  <a href='mailto: support@vaultody.com' className='btn-link text-decoration-underline'>
                    <T>app.contact-support</T>
                  </a>
                </div>
              </div>
            </div>
            <ReCAPTCHA
              size='invisible'
              type='image'
              sitekey='6LdbiH8iAAAAAJk5hD-AQcNi2RsBSpjIKyDv2v93'
              ref={recaptchaRef}
            />
          </form>
        </>
      )}
      {susess && (
        <div className='guest-flow-form-wrapper'>
          <div className='guest-flow-form-main'>
            <div className='susess-text-align'>
              <img src={emailSentIcon} className='forgot-pass-icon' />
              <div className='forgot-pass-sent-title'>
                <T>app.email.sent</T>
              </div>
              <div className='forgot-pass-sent-subtitle'>
                <T>app.check.email</T>
              </div>
            </div>
          </div>
          <Button fullWidth onClick={() => window.location.replace(ROUTE_HOME)}>
            <T>app.back.to.login</T>
          </Button>
          <div className='guest-layout-footer'>
            <T>app.issue-sign-in</T>{' '}
            <a href='mailto: support@vaultody.com' className='btn-link text-decoration-underline'>
              <T>app.contact-support</T>
            </a>
          </div>
        </div>
      )}
    </GuestLayout>
  );
};

export default ForgotPass;
