import { createTrxDraftApi, postWalletTrxStaticFeeApi } from '../../api/endpoints';
import { blockchainTypes } from '../blockchain/blockchain';

export const getSenderAndReceiversWalletNames = (transaction, isTransactionRequests, isDraft) => {
  if (!transaction) return null;
  if (transaction?.transaction?.direction === 'vault-internal') {
    return {
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address || '--',
        vaultType: transaction?.wallet?.vaultType,
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (
    transaction?.transaction?.innerDirection === 'outgoing' &&
    transaction?.transaction?.direction === 'client-internal'
  ) {
    const relatedVaultInfo = transaction?.clientRelatedVaults?.[0];
    return {
      receiver: {
        name: relatedVaultInfo?.name,
        color: relatedVaultInfo?.color,
        address: relatedVaultInfo?.address,
        vaultType: relatedVaultInfo?.vaultType,
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (
    transaction?.transaction?.innerDirection === 'incoming' &&
    transaction?.transaction?.direction === 'client-internal'
  ) {
    const relatedVaultInfo =
      transaction?.clientRelatedVaults?.find((vault) => vault?.address === transaction?.sender) ||
      transaction?.clientRelatedVaults?.[0];
    return {
      sender: {
        name: relatedVaultInfo?.name,
        color: relatedVaultInfo?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: relatedVaultInfo?.vaultType,
      },
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address || '--',
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (transaction?.transaction?.direction === 'incoming') {
    return {
      sender: {
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        ...(transaction?.senderContactName ? { name: transaction?.senderContactName } : {}),
      },
      receiver: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: transaction?.recipients?.[0]?.address || '--',
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (transaction?.transaction?.direction === 'outgoing') {
    return {
      receiver: {
        address: transaction?.recipients?.[0]?.address || '--',
        ...(transaction?.recipients?.[0]?.contactName
          ? { name: transaction?.recipients?.[0]?.contactName }
          : {}),
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
        vaultType: transaction?.wallet?.vaultType,
      },
    };
  }
  if (isDraft) {
    return {
      receiver: {
        name: transaction.recipients?.[0]?.name || transaction.recipients?.[0]?.contactName,
        color: transaction.recipients?.[0]?.color,
        address: transaction.recipients?.[0]?.address || '--',
        vaultType: transaction.recipients?.[0]?.vaultType,
      },
      sender: {
        name: transaction?.senders?.[0]?.name,
        color: transaction?.senders?.[0]?.color,
        address: transaction.senders?.[0]?.address,
        vaultType: transaction?.senders?.[0]?.vaultType,
      },
    };
  }
  if (!Object.keys(transaction?.transaction || {})?.length) {
    const receiverVault = isTransactionRequests
      ? transaction?.recipients?.[0]?.contactName
        ? { name: transaction?.recipients?.[0]?.contactName }
        : transaction?.clientRelatedVaults?.[0]
      : undefined;
    return {
      receiver: {
        name: receiverVault?.name,
        color: receiverVault?.color,
        address: transaction?.recipients?.[0]?.address || '--',
      },
      sender: {
        name: transaction.wallet?.name,
        color: transaction?.wallet?.color,
        address: Array.isArray(transaction.sender) ? transaction.sender[0] : transaction.sender,
      },
    };
  }
  return {
    receiver: undefined,
    sender: undefined,
  };
};

export const createTransactionDraft = async (
  blockchainInfo,
  note,
  strategy,
  walletId,
  asset,
  feePriority,
  recipients,
  amount,
  senders,
  maxFee,
) => {
  const dataSend = {
    item: {
      drafts: [
        {
          blockchain: blockchainInfo?.blockchain,
          note: note || '',
          ...(blockchainTypes.UTXO ? { prepareStrategy: strategy } : {}),
          vaultId: walletId,
          assetId: asset?.assetId,
          feePriority: feePriority,
          ...(blockchainTypes.UTXO
            ? {
                senders: senders?.map((sender) => ({
                  address: sender?.address,
                })),
              }
            : {}),
          tokenData: {
            contractAddress: asset?.assetData?.contract,
            symbol: asset?.symbol,
          },
          recipients: recipients?.map((recepient) => ({
            address: recepient?.address,
            amount:
              blockchainInfo?.type === blockchainTypes.UTXO ? recepient?.selectedAmount : amount?.toString(),
          })),
          ...(maxFee ? { maxFee } : {}),
        },
      ],
    },
  };
  return createTrxDraftApi(dataSend);
};

export const createTransaction = async (
  blockchainInfo,
  note,
  walletId,
  recepients,
  strategy,
  asset,
  amount,
  sender,
  feePriority,
  maxFee,
) => {
  let dataSend;
  if (blockchainInfo?.type === blockchainTypes.UTXO) {
    dataSend = {
      item: {
        assetId: asset?.assetId,
        blockchain: blockchainInfo?.blockchain,
        note: note || '',
        recipients: recepients?.map((recepient) => ({
          id: (Math.random() + 1).toString(36).substring(7),
          address: recepient?.address,
          amount: recepient.selectedAmount,
        })),
        prepareStrategy: strategy,
        feePriority: feePriority?.value,
        ...(maxFee ? { maxFee } : {}),
      },
    };
  } else {
    dataSend = {
      item: {
        assetId: asset?.assetId,
        blockchain: blockchainInfo?.blockchain,
        note: note || '',
        sender: sender,
        recipient: recepients?.[0]?.address || recepients?.address,
        amount: amount?.toString(),
        feePriority: feePriority?.name?.toLowerCase(),
        ...(maxFee ? { maxFee } : {}),
      },
    };
  }
  if (asset?.type?.toUpperCase() === 'TOKEN') {
    dataSend = {
      item: {
        ...dataSend.item,
        ...{ token: asset?.assetData?.contract },
      },
    };
  }
  return postWalletTrxStaticFeeApi(
    walletId,
    blockchainInfo?.type || blockchainTypes?.ACCOUNT,
    asset?.type?.toUpperCase() === 'TOKEN' ? `token/erc-20` : asset?.type?.toLowerCase(),
    blockchainInfo?.blockchainStaticTxFee ? '/static-fee' : '',
    dataSend,
  );
};

export const getApproximateFeeBody = (
  blockchainInfo,
  recepients,
  strategy,
  asset,
  amount,
  feePriority,
  sender,
) => {
  let dataSend;
  if (blockchainInfo?.type === blockchainTypes.UTXO) {
    dataSend = {
      item: {
        assetId: asset?.assetId,
        blockchain: blockchainInfo?.blockchain,
        destinations: recepients?.map((recepient) => ({
          id: (Math.random() + 1).toString(36).substring(7),
          address: recepient?.address,
          amount: recepient.selectedAmount,
        })),
        prepareStrategy: strategy,
        feePriority: feePriority?.value,
        contractAddress: asset?.assetData?.contract,
        sources: [],
      },
    };
  } else {
    dataSend = {
      item: {
        assetId: asset?.assetId,
        blockchain: blockchainInfo?.blockchain,
        destinations: [
          {
            address: recepients?.[0]?.address || recepients?.address,
            amount: amount?.toString(),
          },
        ],
        feePriority: feePriority?.name?.toLowerCase(),
        contractAddress: asset?.assetData?.contract,
        sources: [{ address: sender }],
      },
    };
  }
  if (asset?.type?.toUpperCase() === 'TOKEN') {
    dataSend = {
      item: {
        ...dataSend.item,
        ...{ token: asset?.assetData?.contract },
      },
    };
  }
  return dataSend;
};
