import './WalletGasTankerTable.css';
import { Suspense, lazy, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { T, t } from '../../../T';
import { checkLimits } from '../../../../helpers/limits';
import { getAssetsByAddressApi, getWalletAddressesApi } from '../../../../api/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { setMyUsage } from '../../../../redux/slices/userSlice';
import Button from '../../../Button/Button';
import Accordion from '../../../Accordion/Accordion';
import AssetAccordionDetails from '../../../Pages/Assets/AssetAccordionDetails/AssetAccordionDetails';
import LoadMore from '../../../LoadMore/LoadMore';
import AddressAccordionTitle from '../../../Pages/Wallets/AddressAccordionTitle';
import { ROUTE_WALLET } from '../../../../routes/routes';
import { ReactComponent as GasTankerIcon } from '../../../../assets/icons/gas-tanker-icon.svg';
import Skeleton from '../../../Skeleton/Skeleton';
import { useMediaQuery } from 'react-responsive';
const VTooltip = lazy(() => import('../../../VTooltip/VTooltip'));
const LimitsModal = lazy(() => import('../../../Modals/LimitsModal'));
const GenerateAddressModal = lazy(() => import('../../../Modals/GenerateAddressModal'));

const WalletGasTankerTable = ({ id, walletData, backup }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 950 });
  const limits = useSelector((state) => state.userReducer.limits);
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const trialBannerVisible = useSelector((state) => state.globalReducer.trialBannerVisible);
  const [dataAdresses, setDataAdresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [accordionOpenIndexes, setAccordionOpenIndexes] = useState([]);
  const [addressAssets, setAddressAssets] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [limitModal, setLimitModal] = useState(false);
  const [loadMoreVisible, setLoadMoreVisible] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const addressCreatePermission = myProfile.premmisions?.includes('WALLETS_ADDRESS_CREATE');

  const getAssetsByAddress = (addressBlockchain, addressId, accordionIndex) => {
    setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: true }));
    getAssetsByAddressApi(id, addressBlockchain, addressId)
      .then(async (res) => {
        const sortedAssets = res?.data?.items?.sort((a, b) => {
          return b?.assetData?.amount * b?.exchangeRate - a?.assetData?.amount * a?.exchangeRate;
        });
        setAddressAssets((prev) => ({ ...prev, [accordionIndex]: sortedAssets }));
      })
      .finally(() => setLoadingAssets((prev) => ({ ...prev, [accordionIndex]: false })));
  };

  const getDataAdresses = () => {
    setLoading(true);
    getWalletAddressesApi(id, { params: { limit: 50, addressTypes: ['station'] } })
      .then((res) => {
        setDataAdresses(res.data.items);
        if (res.data.items.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        setLoadMoreVisible(res?.data?.skip + res?.data?.limit < res?.data?.total);
      })
      .catch(() => setLoadMoreVisible(false))
      .finally(() => setLoading(false));
  };

  const loadMoreHandler = async () => {
    setLoadMoreLoading(true);
    const lastRecord = dataAdresses[dataAdresses.length - 1];
    getWalletAddressesApi(id, {
      params: {
        startingAfter: lastRecord.id,
        limit: 10,
        addressTypes: ['station'],
      },
    })
      .then((res) => {
        setDataAdresses((prevState) => [...prevState, ...(res?.data?.items?.length ? res.data.items : [])]);
        if (res.data.items.length === 0) {
          setLoadMoreVisible(false);
          return;
        }
        setLoadMoreVisible(res?.data?.skip + res?.data?.limit < res?.data?.total);
      })
      .finally(() => setLoadMoreLoading(false));
  };

  useEffect(() => {
    getDataAdresses();
  }, []);

  return (
    <>
      <div>
        <div className='gas-tanker-wallet-button-filters-wrapper'>
          <div className='button-holder'>
            <Button
              disabled={!addressCreatePermission || !backup}
              size='md'
              onClick={async () => {
                await checkLimits((usage) => dispatch(setMyUsage(usage)));
                if (limits[ROUTE_WALLET].from >= limits[ROUTE_WALLET].to) {
                  setLimitModal(true);
                } else {
                  setOpenModal(true);
                }
              }}
              data-tooltip-id='gas-tanker-create-button'
              data-tooltip-content={
                !addressCreatePermission ? t(lng, 'app.role.limitation') : t(lng, 'app.backup.secure')
              }
            >
              {(!addressCreatePermission || !backup) && (
                <Suspense fallback={null}>
                  <VTooltip id='gas-tanker-create-button' />
                </Suspense>
              )}
              <GasTankerIcon
                className={`gas-tanker-icon-generate-vault ${
                  !myProfile.premmisions?.includes('WALLETS_ADDRESS_CREATE') || !backup ? 'disabled' : ''
                }`}
              />
              <T>app.generate.new.gas.tanker</T>
            </Button>
          </div>
        </div>
        <div
          className={`gas-tanker-accordion-table-parent-wallets ${
            trialBannerVisible ? 'trial-banner-visible' : ''
          }`}
        >
          <div className='gas-tanker-table-content-wallet-addresses-table'>
            <div className='table-row caption'>
              <div className='gas-tanker-addresses-table-data name gas-tanker-wallet-addresses-table-header'>
                <T>app.address.name</T>
              </div>
              <div className='gas-tanker-addresses-table-data blockchain gas-tanker-wallet-addresses-table-header'>
                <T>app.blockchain</T>
              </div>
              <div className='gas-tanker-addresses-table-data trx-address gas-tanker-wallet-addresses-table-header'>
                <T>app.gas.tanker.address</T>
              </div>
              <div className='gas-tanker-addresses-table-data created-at gas-tanker-wallet-addresses-table-header'>
                <T>app.available.balance</T>
              </div>
              <div className='gas-tanker-addresses-table-data settings gas-tanker-wallet-addresses-table-header' />
            </div>
            {loading && (
              <div className='loading-full-height'>
                <Skeleton rowHeight={isMobile ? 306 : 103} padding={isMobile ? 20 : 50} />
              </div>
            )}
            {!loading &&
              dataAdresses.map((address, index) => (
                <Accordion
                  key={index}
                  open={accordionOpenIndexes?.includes(index)}
                  onOpenCallback={(isAccordionOpened) => {
                    if (!isAccordionOpened) {
                      setAccordionOpenIndexes((prev) => [...prev, index]);
                      getAssetsByAddress(address.blockchain, address.address, index);
                    } else {
                      setAccordionOpenIndexes((prev) =>
                        prev?.filter((accordionIndex) => accordionIndex !== index),
                      );
                    }
                  }}
                  titleComponent={<AddressAccordionTitle address={address} />}
                  detailsComponent={
                    loadingAssets[index] ? (
                      <div className='team-page wallets wallets-address'>
                        <div className='loading'>
                          <ReactLoading type='spin' color='##020D1C' />
                        </div>
                      </div>
                    ) : (
                      <AssetAccordionDetails
                        addressId={address?.address}
                        walletData={walletData}
                        blockchain={address?.blockchain}
                        rows={addressAssets?.[index] || []}
                        type='assets'
                      />
                    )
                  }
                />
              ))}
            {!loading && loadMoreVisible && (
              <LoadMore isLoading={loadMoreLoading} loadMoreHandler={loadMoreHandler} />
            )}
            {!loading && dataAdresses.length === 0 && (
              <div className='gas-tanker-empty-addresses-label'>
                <div>
                  <T>app.no.gas.tankers</T>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <Suspense fallback={null}>
          <GenerateAddressModal
            id={id}
            toggleFunction={setOpenModal}
            sussessFunction={getDataAdresses}
            type='station'
            walletType={walletData?.vaultType?.toLowerCase()}
          />
        </Suspense>
      )}
      {limitModal && (
        <Suspense fallback={null}>
          <LimitsModal toggleFunction={setLimitModal} modalName='app.addresses.limit' />
        </Suspense>
      )}
    </>
  );
};

export default WalletGasTankerTable;
