import {
  allTransactionStatuses,
  transactionDraftsStatuses,
} from '../../helpers/transactionrequests/statuses';
import { T, t } from '../T';
import { v4 as uuidv4 } from 'uuid';
import { Mobile } from '../Breakpoints';
import { useSelector } from 'react-redux';
import VaultIcon from '../VaultIcon/VaultIcon';
import CopyButton from '../CopyButton/CopyButton';
import CryptoIcon from '../CryptoIcon/CryptoIcon';
import { currency } from '../../helpers/currency';
import { smallAddress } from '../../helpers/strings';
import CheckboxNew from '../CheckboxNew/CheckboxNew';
import { Suspense, lazy, useEffect, useState } from 'react';
import { getBlockchainScannerLink } from '../../helpers/blockchain/blockchain';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-icon.svg';
import { blockchainScannersIcons } from '../../helpers/protocolIcons/protocolIcons';
import { ReactComponent as Checkmark } from '../../assets/icons/green-checkmark.svg';
import { getSenderAndReceiversWalletNames } from '../../helpers/transactionrequests/utils';
import { ReactComponent as ContactsIcon } from '../../assets/icons/dashboardMenu/contacts-icon.svg';
const VTooltip = lazy(() => import('../VTooltip/VTooltip'));

const TransactionRequestsRow = ({
  isDraft,
  direction,
  isSelected,
  transaction,
  innerDirection,
  detailsHandler,
  recepientAmount,
  recepientAmountInUSD,
  handleOpenRemoveModal,
  isTransactionRequests,
  setAddNewContactModal,
  handleSelectTransaction,
  draftDeleteButtonVisible,
}) => {
  const myProfile = useSelector((state) => state.userReducer.myProfile);
  const protocols = useSelector((state) => state.globalReducer.protocols);
  const lng = useSelector((state) => state.localizationReducer.selectedData);
  const statusObj = (isDraft ? transactionDraftsStatuses : allTransactionStatuses)?.find(
    (status) => status?.value?.toUpperCase() === transaction.status?.toUpperCase(),
  );
  const [vaultodyDateFunc, setVaultodyDateFunc] = useState();
  const senderAndReceiversWalletNames = getSenderAndReceiversWalletNames(
    transaction,
    isTransactionRequests,
    isDraft,
  );

  const handleAddContact = (address) => {
    setAddNewContactModal({
      open: true,
      blockchain: [
        protocols?.find(
          (protocol) => protocol?.blockchain?.toLowerCase() === transaction?.blockchain?.toLowerCase(),
        ),
      ],
      address,
    });
  };

  useEffect(() => {
    const loadVaultodyDate = async () => {
      const module = await import('../../helpers/date');
      setVaultodyDateFunc(() => module.vaultodyDate);
    };
    loadVaultodyDate();
  }, []);

  return (
    <div className='table-row data'>
      <div className='table-data date timestamp'>
        <span className='mobile-cell-title'>
          <T>app.date.time</T>
        </span>
        {isDraft ? (
          <>
            <CheckboxNew
              disabled={transaction?.status?.toLowerCase() !== 'created'}
              id='selectedCheckbox'
              reverse
              multiSelect
              value={isSelected}
              disabledHoverEffect
              onChange={() => handleSelectTransaction(transaction?.id, isSelected)}
              label={
                <span className='draft-trx-row-select-label'>
                  {vaultodyDateFunc ? vaultodyDateFunc(transaction?.createdTimestamp, 'full') : null}
                </span>
              }
            />
            <span className='mobile-createdat-trx-date'>
              {vaultodyDateFunc ? vaultodyDateFunc(transaction?.createdTimestamp, 'full') : null}
            </span>
          </>
        ) : (
          <div className='transaction-mobile-flex'>
            {(direction === 'vault-internal' || direction === 'client-internal') &&
              innerDirection === 'incoming' && (
                <>
                  <span
                    data-tooltip-id='trx-internal-incoming'
                    data-tooltip-content={t(lng, 'app.transaction.internal.incoming')}
                    className='icon-receive icon even'
                  />
                  <Suspense fallback={null}>
                    <VTooltip id='trx-internal-incoming' />
                  </Suspense>
                </>
              )}
            {(direction === 'vault-internal' || direction === 'client-internal') &&
              innerDirection === 'outgoing' && (
                <>
                  <span
                    data-tooltip-id='trx-internal-outgoing'
                    data-tooltip-content={t(lng, 'app.transaction.internal.outgoing')}
                    className='icon-send icon even'
                  />
                  <Suspense fallback={null}>
                    <VTooltip id='trx-internal-outgoing' />
                  </Suspense>
                </>
              )}
            {direction === 'incoming' && (
              <>
                <span
                  data-tooltip-id='trx-incoming'
                  data-tooltip-content={t(lng, 'app.transaction.incoming')}
                  className='icon-receive icon'
                />
                <Suspense fallback={null}>
                  <VTooltip id='trx-incoming' />
                </Suspense>
              </>
            )}
            {direction === 'outgoing' && (
              <>
                <span
                  data-tooltip-id='trx-outgoing'
                  data-tooltip-content={t(lng, 'app.transaction.outgoing')}
                  className='icon-send icon'
                />
                <Suspense fallback={null}>
                  <VTooltip id='trx-outgoing' />
                </Suspense>
              </>
            )}
            {Object.keys(transaction?.transaction || {}).length === 0 && (
              <>
                <span
                  data-tooltip-id='trx-outgoing-default'
                  data-tooltip-content={t(lng, 'app.transaction.outgoing')}
                  className='icon-send icon'
                />
                <Suspense fallback={null}>
                  <VTooltip id='trx-outgoing-default' />
                </Suspense>
              </>
            )}
            <span className='tr-row-date'>
              {vaultodyDateFunc ? vaultodyDateFunc(transaction?.createdTimestamp, 'full') : null}
            </span>
          </div>
        )}
      </div>
      <div className={`table-data wallet ${isDraft ? 'draft' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.from</T>
        </span>
        <div className='transaction-mobile-flex'>
          <div className='wallet-cell'>
            {senderAndReceiversWalletNames?.sender?.name && senderAndReceiversWalletNames?.sender?.color && (
              <VaultIcon
                id={uuidv4()}
                name={senderAndReceiversWalletNames?.sender?.name}
                color={senderAndReceiversWalletNames?.sender?.color}
                vaultType={senderAndReceiversWalletNames?.sender?.vaultType}
              />
            )}
            <div className='wallet-info-table'>
              <div
                className='name-wallet'
                data-tooltip-id='trx-wallet-sender-name'
                data-tooltip-content={senderAndReceiversWalletNames?.sender?.name}
              >
                <Suspense fallback={null}>
                  <VTooltip id='trx-wallet-sender-name' />
                </Suspense>
                {senderAndReceiversWalletNames?.sender?.name}
              </div>
              {(transaction.sender || transaction?.senders?.length > 0) && (
                <div
                  className='address-sender'
                  data-tooltip-id='trx-wallet-sender-address'
                  data-tooltip-content={senderAndReceiversWalletNames?.sender?.address}
                >
                  <Suspense fallback={null}>
                    <VTooltip id='trx-wallet-sender-address' />
                  </Suspense>
                  {smallAddress(senderAndReceiversWalletNames?.sender?.address, 8)}
                </div>
              )}
            </div>
          </div>
          {!senderAndReceiversWalletNames?.sender?.name &&
            transaction?.transaction?.direction === 'incoming' && (
              <div
                className='trx-row-add-contact-wrapper'
                data-tooltip-id='trx-add-contact'
                data-tooltip-content={t(lng, 'app.add.contact')}
                onClick={() => handleAddContact(senderAndReceiversWalletNames?.sender?.address)}
              >
                <Suspense fallback={null}>
                  <VTooltip id='trx-add-contact' />
                </Suspense>
                <ContactsIcon className='trx-row-add-contact' />
              </div>
            )}
          {senderAndReceiversWalletNames?.sender?.address && (
            <CopyButton element={senderAndReceiversWalletNames?.sender?.address} />
          )}
        </div>
      </div>
      <div className={`table-data wallet ${isDraft ? 'draft' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.to</T>
        </span>
        <div className='transaction-mobile-flex'>
          <div className='wallet-cell'>
            {senderAndReceiversWalletNames?.receiver?.name &&
              senderAndReceiversWalletNames?.receiver?.color && (
                <VaultIcon
                  id={uuidv4()}
                  name={senderAndReceiversWalletNames?.receiver?.name}
                  color={senderAndReceiversWalletNames?.receiver?.color}
                  vaultType={senderAndReceiversWalletNames?.receiver?.vaultType}
                />
              )}
            <div className='wallet-info-table'>
              <div
                className='name-wallet'
                data-tooltip-id='trx-wallet-receiver-name'
                data-tooltip-content={senderAndReceiversWalletNames?.receiver?.name}
              >
                <Suspense fallback={null}>
                  <VTooltip id='trx-wallet-receiver-name' />
                </Suspense>
                {senderAndReceiversWalletNames?.receiver?.name}
              </div>
              <div
                className='address-sender'
                data-tooltip-id='trx-wallet-receiver-address'
                data-tooltip-content={`${senderAndReceiversWalletNames?.receiver?.address}
              ${transaction.recipients.length > 1 ? ` + ${transaction.recipients.length - 1}` : ''}`}
              >
                <Suspense fallback={null}>
                  <VTooltip id='trx-wallet-receiver-address' />
                </Suspense>
                {smallAddress(senderAndReceiversWalletNames?.receiver?.address, 8)}
                {transaction.recipients.length > 1 && ` + ${transaction.recipients.length - 1}`}
              </div>
            </div>
          </div>
          {((!senderAndReceiversWalletNames?.receiver?.name &&
            transaction?.transaction?.direction === 'outgoing') ||
            (!senderAndReceiversWalletNames?.receiver?.name && isTransactionRequests)) && (
            <div
              className='trx-row-add-contact-wrapper'
              data-tooltip-id='trx-add-contact'
              data-tooltip-content={t(lng, 'app.add.contact')}
              onClick={() => handleAddContact(senderAndReceiversWalletNames?.receiver?.address)}
            >
              <Suspense fallback={null}>
                <VTooltip id='trx-add-contact' />
              </Suspense>
              <ContactsIcon className='trx-row-add-contact' />
            </div>
          )}
          <CopyButton element={transaction.recipients?.[0].address} />
        </div>
      </div>
      <div className='table-data level amount'>
        <span className='mobile-cell-title'>
          <T>app.amount</T>
        </span>
        <div className='transaction-mobile-flex'>
          <div className='trx-row-symbol-wrapper'>
            <CryptoIcon className='token-image' symbol={transaction?.asset?.symbol} />
            {transaction?.asset?.symbol?.toLowerCase() !==
              protocols
                ?.find(
                  (protocol) =>
                    protocol?.blockchain?.toLowerCase() === transaction?.blockchain?.toLowerCase(),
                )
                ?.currency?.toLowerCase() && (
              <CryptoIcon
                className='trx-row-secondary-symbol-icon'
                symbol={
                  protocols?.find(
                    (protocol) =>
                      protocol?.blockchain?.toLowerCase() === transaction?.blockchain?.toLowerCase(),
                  )?.currency
                }
              />
            )}
          </div>
          <div className='amount-info'>
            <div>
              {!transaction.amounts ? (
                <div
                  className='amount-text'
                  data-tooltip-id='trx-no-amounts'
                  data-tooltip-content={`${recepientAmount} ${transaction?.asset?.symbol}`}
                >
                  <Suspense fallback={null}>
                    <VTooltip id='trx-no-amounts' />
                  </Suspense>
                  {recepientAmount} {transaction?.asset?.symbol}
                </div>
              ) : (
                <div className='amount-text'>
                  <span
                    data-tooltip-id='trx-amounts'
                    data-tooltip-content={`${transaction.amounts.walletRecipientAmount} ${transaction.asset.symbol}`}
                  >
                    <Suspense fallback={null}>
                      <VTooltip id='trx-amounts' />
                    </Suspense>
                    {transaction.amounts.walletRecipientAmount} {transaction.asset.symbol}
                  </span>
                </div>
              )}
              <div>
                {!transaction.amounts ? (
                  <div className='amount-text-sub'>{currency(recepientAmountInUSD)}</div>
                ) : (
                  <div className='amount-text-sub'>
                    {currency(transaction.amounts.recipientConvertedAmount)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`table-data trx-status ${isDraft ? 'draft' : ''}`}>
        <span className='mobile-cell-title'>
          <T>app.status</T>
        </span>
        <div className='transaction-mobile-flex'>
          <img src={statusObj?.icon} className='status-image' />
          <div className='status-name capitalize'>{statusObj?.name}</div>
        </div>
      </div>
      <div className='table-data settings block-chain'>
        <div className='transaction-mobile-flex-buttons'>
          <div
            className={`button-holder ${
              (isTransactionRequests && !isDraft) ||
              !isTransactionRequests ||
              ((transaction?.status?.toLowerCase() === 'deleted' ||
                transaction?.status?.toLowerCase() === 'failed') &&
                isDraft)
                ? 'fullWidth'
                : ''
            }`}
          >
            {isDraft ? (
              <div className='draft-delete-icon-wrapper'>
                <div
                  className='cancel-button'
                  onClick={() => {
                    detailsHandler(transaction, isDraft);
                  }}
                >
                  <T>app.draft.info</T>
                </div>
              </div>
            ) : (
              <>
                {(direction === 'incoming' ||
                  ((direction === 'vault-internal' || direction === 'client-internal') &&
                    innerDirection === 'incoming')) &&
                  Object.keys(transaction.transaction).length > 0 && (
                    <div
                      className='blockexplorer-button'
                      onClick={() => {
                        window.open(
                          getBlockchainScannerLink(
                            transaction.blockchain.toLowerCase(),
                            transaction.transaction.id,
                            transaction.network.toLowerCase(),
                          ),
                          '_blank',
                        );
                      }}
                    >
                      <img
                        className='blochain-scanner-logo'
                        src={blockchainScannersIcons(transaction.blockchain.toLowerCase())}
                      />
                    </div>
                  )}
                {(direction === 'outgoing' ||
                  ((direction === 'vault-internal' || direction === 'client-internal') &&
                    innerDirection === 'outgoing') ||
                  Object.keys(transaction?.transaction || {}).length === 0) && (
                  <div
                    className='cancel-button'
                    onClick={() => {
                      detailsHandler(transaction);
                    }}
                  >
                    <T>app.details</T>
                  </div>
                )}
              </>
            )}
          </div>
          {myProfile.premmisions?.includes('TX_DRAFT_DELETE') &&
            transaction?.status === 'created' &&
            draftDeleteButtonVisible && (
              <DeleteIcon
                className='trx-row-settings-delete-icon'
                onClick={() => handleOpenRemoveModal({ open: true, transaction: transaction })}
              />
            )}
          {isDraft &&
            transaction?.status?.toLowerCase() !== 'deleted' &&
            transaction?.status?.toLowerCase() !== 'failed' && (
              <Mobile>
                <div className='button-holder trx-mobile-delete'>
                  <div
                    className='cancel-button trx-mobile-delete'
                    onClick={() => handleOpenRemoveModal({ open: true, transaction: transaction })}
                  >
                    <T>app.delete.automation.rule</T>
                  </div>
                </div>
              </Mobile>
            )}
        </div>
        {isDraft &&
          transaction?.status?.toLowerCase() !== 'deleted' &&
          transaction?.status?.toLowerCase() !== 'failed' && (
            <Mobile>
              <div
                className={`cancel-button trx-mobile-select-draft ${isSelected ? 'selected' : ''}`}
                onClick={() => handleSelectTransaction(transaction?.id, isSelected)}
              >
                {isSelected ? (
                  <>
                    <Checkmark />
                    <T>app.selected</T>
                  </>
                ) : (
                  <T>app.select.draft</T>
                )}
              </div>
            </Mobile>
          )}
      </div>
    </div>
  );
};

export default TransactionRequestsRow;
